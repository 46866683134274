import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BackgroundSection from "../components/Globals/BackgroundSection"
import Contact from "../components/Home/Contact"

const ContactPage = ({ data }) => {
  const image = getImage(data.image.childImageSharp.gatsbyImageData)
  return (
    <Layout>
      <Seo title="Contact" />
      <BackgroundSection
        img={image}
        title="Contact"
        description="Get in Touch with Us!"
        styleClass="about-background background-images"
      />
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  {
    image: file(relativePath: { eq: "lobster3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 3000
          quality: 50
          placeholder: NONE
          blurredOptions: { width: 100 }
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }
  }
`

export default ContactPage
