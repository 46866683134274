import React from "react"
import Title from "../Globals/Title"

export default function Contact() {
  return (
    <section className="container contact contact-background py-5">
      <Title title="Contact Us" />

      <div className="row">
        <div className="col-10 col-sm-8 col-md-6 mx-auto">
          <div className="text-center">
            <p className="about-p">
              For job opportunities, please email us at
              contact@crabstationodessatexas.com
            </p>
            <p className="about-p">
              For all other inquiries, send us a message via the form below and
              we’ll get back to you as soon as possible.
            </p>
          </div>
          <form action="https://formspree.io/f/xeqvqgvd" method="POST">
            {/* Name */}
            <div className="form-group menu-text-color">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                id="name"
                placeholder="Name"
              />
            </div>
            {/* Email */}
            <div className="form-group menu-text-color">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                name="_replyto"
                id="email"
                placeholder="Email"
              />
            </div>
            {/* Phone */}
            <div className="form-group menu-text-color">
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                className="form-control"
                name="phone"
                id="phone"
                placeholder="Phone Number"
              />
            </div>
            {/* Subject */}
            <div className="form-group menu-text-color">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                className="form-control"
                name="subject"
                id="subject"
                placeholder="What are you getting in touch about?"
              />
            </div>
            {/* Description */}
            <div className="form-group menu-text-color">
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="description"
                className="form-control"
                rows="5"
                placeholder="Your Message"
              ></textarea>
            </div>
            {/* Submit */}
            <button
              type="submit"
              className="btn home-link-button text-capitalize mt-5"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}
